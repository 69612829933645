(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('skipEvaluationDialogController', skipEvaluationDialogController);

    skipEvaluationDialogController.$inject = ['$stateParams', '$state', '$uibModalInstance', 'entity', 'HaqEvaluationSkipsService'];

    function skipEvaluationDialogController($stateParams, $state, $uibModalInstance, entity, HaqEvaluationSkipsService) {
        var vm = this;
        vm.clear = clear;
        vm.save = save;
        vm.isSaving = false;
        vm.skipReviewEvaluation = entity;

        init();

        function init() {
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function save() {
            if (vm.isSaving || !vm.skipReviewEvaluation.reason) {
                return;
            }
            vm.isSaving = true;
            HaqEvaluationSkipsService.addHaqEvaluationSkip(vm.skipReviewEvaluation).then(function (res) {
                onSaveSuccess(res);
            }, function errorSave(error) {
                onSaveError(error);
            });
        }

        function onSaveSuccess(result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError(error) {
            vm.isSaving = false;
            // var errorMessage = error.headers('X-ipmApp-error-message');
            if (error.status !== 200) {
                $uibModalInstance.close(error);
            }
        }
    }
})();
