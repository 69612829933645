(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('HaqEvaluationSkipsController', HaqEvaluationSkipsController);

    HaqEvaluationSkipsController.$inject = ['$scope', '$state', 'HaqEvaluationSkipsService', 'ipmConstants', '$uibModal'];

    function HaqEvaluationSkipsController ($scope, $state, HaqEvaluationSkipsService, constants, $uibModal) {
        var vm = this;
        vm.haqTracks = [];
        vm.haqSkips = [];
        vm.totalItems = 0;
        vm.pageSize = constants.PAGE_SIZE;
        vm.page = 1;
        vm.predicate = 'dateSkipped';
        vm.reverse = false;
        vm.hideRemoved = true;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.onChangeShowRemovedTests = onChangeShowRemovedTests;
        vm.showReasonDialog = showReasonDialog;

        init();

        function init() {
            vm.pageableAP = {
                page: 1,
                size: constants.PAGE_SIZE,
                totalItems: 0
            };
            vm.loadAll();
        }

        function loadAll() {
            var pageable = {
                page: vm.pageableAP.page > 0 ? vm.pageableAP.page - 1 : vm.pageableAP.page,
                size: vm.pageSize,
                sort: sort()
            };

            var skipFilter = {
                hideRemoved: vm.hideRemoved
            };
            HaqEvaluationSkipsService.findAll(skipFilter, pageable).then(function (response) {
                vm.pageableAP.totalItems = response && response.totalItems ? response.totalItems : 0;
                vm.haqSkips = response && response.data ? response.data : [];
                _.forEach(vm.haqSkips, function(o) {
                        o.reasonUi = o.reason;
                        o.reasonView = false;
                    if (o.reason && o.reason.length > 14) {
                        o.reasonUi = o.reason.slice(0, 10) + " ...";
                        o.reasonView = true;
                    }
                });
            });
        };

        function sort() {
            var result = [vm.predicate + ',' + (vm.reverse ? 'desc' : 'asc')];
            if (vm.predicate !== 'id') {
                result.push('id' + ',' + (vm.reverse ? 'desc' : 'asc'));
            }
            return result;
        }

        function loadPage(page) {
            vm.page = page;
            vm.loadAll();
        }

        function onChangeShowRemovedTests() {
            vm.hideRemoved = !vm.hideRemoved;
        	vm.loadAll();
        }

        function showReasonDialog(reason) {
        	$uibModal.open({
                controller: function ($uibModalInstance) {
                	var vm = this;
                	vm.reason = reason;
                	vm.close = function() {
                		$uibModalInstance.dismiss('cancel');
                	}
                },
                templateUrl: 'app/entities/haq-evaluation-skips/skip-reason-dialog.html',
                controllerAs: 'vm',
                size: 'sm',
                backdrop: 'static',
                windowClass: 'absolute-center warning-dialog'
            });
        };        
    }
})();
