(function() {
    'use strict';

    angular
        .module('ipmApp')
        .controller('HaqEvaluationSkipsRemoveController', HaqEvaluationSkipsRemoveController);

    HaqEvaluationSkipsRemoveController.$inject = ['$uibModalInstance', 'entity', 'HaqEvaluationSkipsService'];

    function HaqEvaluationSkipsRemoveController($uibModalInstance, entity, HaqEvaluationSkipsService) {
        var vm = this;
        vm.haqEvaluationSkip = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.confirmDelete = function (id) {
            HaqEvaluationSkipsService.removeHaqEvaluationSkip(id).then(function () {
                $uibModalInstance.close();
            });
        };
    }
})();
