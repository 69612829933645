(function() {
    'use strict';

    angular
        .module('ipmApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('haq-evaluation-skips', {
            parent: 'entity',
            url: '/haq-evaluation-skips',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'ipmApp.haqEvaluationSkips.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/haq-evaluation-skips/haq-evaluation-skips.html',
                    controller: 'HaqEvaluationSkipsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('haq-evaluation-skips');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('haq-evaluation-skips.add-back', {
                parent: 'haq-evaluation-skips',
                url: '/{id}/add-back',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/haq-evaluation-skips/haq-evaluation-skips-add-dialog.html',
                        controller: 'HaqEvaluationSkipsAddController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'md',
                        windowClass: 'center-dialog-message vertical-center',
                        resolve: {
                            entity: function (HaqEvaluationSkipsService) {
                                return HaqEvaluationSkipsService.findById($stateParams.id);
                            },
                        }
                    }).result.then(function() {
                        $state.go('haq-evaluation-skips', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            })
            .state('haq-evaluation-skips.remove', {
                parent: 'haq-evaluation-skips',
                url: '/{id}/remove',
                data: {
                    authorities: ['ROLE_ADMIN']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/haq-evaluation-skips/haq-evaluation-skips-remove-dialog.html',
                        controller: 'HaqEvaluationSkipsRemoveController',
                        controllerAs: 'vm',
                        size: 'sm',
                        windowClass: 'absolute-center',
                        resolve: {
                            entity: ['HaqEvaluationSkipsService', function(HaqEvaluationSkipsService) {
                                return HaqEvaluationSkipsService.findById($stateParams.id);
                            }]
                        }
                    }).result.then(function() {
                        $state.go('haq-evaluation-skips', null, { reload: true });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });;
    }
})();
